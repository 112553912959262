export default {
  methods: {
    $hasAnyAuthoritySync(authorities: any) {
      if (typeof authorities === 'string') {
        authorities = [authorities];
      }

      if (!this.$store.getters.account) {
        return false;
      }

      for (const authority of authorities) {
        if (this.$store.getters.account.authorities.includes(authority)) {
          return true;
        }
      }
    },
  },
};
