import {Authority} from "@/shared/security/authority";
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Marketplace = () => import('@/entities/marketplace/marketplace.vue');
// prettier-ignore
const MarketplaceUpdate = () => import('@/entities/marketplace/marketplace-update.vue');
// prettier-ignore
const MarketplaceDetails = () => import('@/entities/marketplace/marketplace-details.vue');
// prettier-ignore
const PosImplementation = () => import('@/entities/pos-implementation/pos-implementation.vue');
// prettier-ignore
const PosImplementationUpdate = () => import('@/entities/pos-implementation/pos-implementation-update.vue');
// prettier-ignore
const PosImplementationDetails = () => import('@/entities/pos-implementation/pos-implementation-details.vue');
// prettier-ignore
const Metadata = () => import('@/entities/metadata/metadata.vue');
// prettier-ignore
const MetadataUpdate = () => import('@/entities/metadata/metadata-update.vue');
// prettier-ignore
const MetadataDetails = () => import('@/entities/metadata/metadata-details.vue');
// prettier-ignore
const Partner = () => import('@/entities/partner/partner.vue');
// prettier-ignore
const PartnerUpdate = () => import('@/entities/partner/partner-update.vue');
// prettier-ignore
const PartnerDetails = () => import('@/entities/partner/partner-details.vue');
// prettier-ignore
const PartnerMasterDetails = () => import('@/entities/partner/partner-master-details.vue');
// prettier-ignore
const ConfigEndpoint = () => import('@/entities/config-endpoint/config-endpoint.vue');
// prettier-ignore
const ConfigEndpointUpdate = () => import('@/entities/config-endpoint/config-endpoint-update.vue');
// prettier-ignore
const ConfigEndpointDetails = () => import('@/entities/config-endpoint/config-endpoint-details.vue');
// prettier-ignore
const ConfigOperation = () => import('@/entities/config-operation/config-operation.vue');
// prettier-ignore
const ConfigOperationUpdate = () => import('@/entities/config-operation/config-operation-update.vue');
// prettier-ignore
const ConfigOperationDetails = () => import('@/entities/config-operation/config-operation-details.vue');
// prettier-ignore
const ConfigPosApiKey = () => import('@/entities/config-pos-api-key/config-pos-api-key.vue');
// prettier-ignore
const ConfigPosApiKeyUpdate = () => import('@/entities/config-pos-api-key/config-pos-api-key-update.vue');
// prettier-ignore
const ConfigPosApiKeyDetails = () => import('@/entities/config-pos-api-key/config-pos-api-key-details.vue');
// prettier-ignore
const ConfigDefaultStoreKey = () => import('@/entities/config-default-store-key/config-default-store-key.vue');
// prettier-ignore
const ConfigDefaultStoreKeyUpdate = () => import('@/entities/config-default-store-key/config-default-store-key-update.vue');
// prettier-ignore
const ConfigDefaultStoreKeyDetails = () => import('@/entities/config-default-store-key/config-default-store-key-details.vue');
// prettier-ignore
const MenuUpdateRequest = () => import('@/entities/menu-update-request/menu-update-request.vue');
// prettier-ignore
const MenuUpdateRequestUpdate = () => import('@/entities/menu-update-request/menu-update-request-update.vue');
// prettier-ignore
const MenuUpdateRequestDetails = () => import('@/entities/menu-update-request/menu-update-request-details.vue');
// prettier-ignore
const Store = () => import('@/entities/store/store.vue');
// prettier-ignore
const StoreUpdate = () => import('@/entities/store/store-update.vue');
// prettier-ignore
const StoreDetails = () => import('@/entities/store/store-details.vue');
// prettier-ignore
const StoreCron = () => import('@/entities/store-cron/store-cron.vue');
// prettier-ignore
const StoreCronUpdate = () => import('@/entities/store-cron/store-cron-update.vue');
// prettier-ignore
const StoreCronDetails = () => import('@/entities/store-cron/store-cron-details.vue');
// prettier-ignore
const TestStore = () => import('@/entities/test-store/test-store.vue');
// prettier-ignore
const TestStoreUpdate = () => import('@/entities/test-store/test-store-update.vue');
// prettier-ignore
const TestStoreDetails = () => import('@/entities/test-store/test-store-details.vue');
// prettier-ignore
const PosTestStore = () => import('@/entities/pos-test-store/pos-test-store.vue');
// prettier-ignore
const PosTestStoreUpdate = () => import('@/entities/pos-test-store/pos-test-store-update.vue');
// prettier-ignore
const PosTestStoreDetails = () => import('@/entities/pos-test-store/pos-test-store-details.vue');
// prettier-ignore
const Country = () => import('@/entities/country/country.vue');
// prettier-ignore
const CountryUpdate = () => import('@/entities/country/country-update.vue');
// prettier-ignore
const CountryDetails = () => import('@/entities/country/country-details.vue');
// prettier-ignore
const MarketplaceContact = () => import('@/entities/marketplace-contact/marketplace-contact.vue');
// prettier-ignore
const MarketplaceContactUpdate = () => import('@/entities/marketplace-contact/marketplace-contact-update.vue');
// prettier-ignore
const MarketplaceContactDetails = () => import('@/entities/marketplace-contact/marketplace-contact-details.vue');
// prettier-ignore
const PosContact = () => import('@/entities/pos-contact/pos-contact.vue');
// prettier-ignore
const PosContactUpdate = () => import('@/entities/pos-contact/pos-contact-update.vue');
// prettier-ignore
const PosContactDetails = () => import('@/entities/pos-contact/pos-contact-details.vue');
// prettier-ignore
const IntegratorApiKey = () => import('@/entities/integrator-api-key/integrator-api-key.vue');
// prettier-ignore
const IntegratorApiKeyUpdate = () => import('@/entities/integrator-api-key/integrator-api-key-update.vue');
// prettier-ignore
const IntegratorApiKeyDetails = () => import('@/entities/integrator-api-key/integrator-api-key-details.vue');
// const EmailComponent = () => import('@/entitites/emails/email.vue');
const EmailNew = () => import('@/entities/emails/email-new.vue');

const LaasOrders = () => import('@/entities/laas-eligible-orders/laas-eligible-orders.vue');
const LaasOrdersCreate = () => import('@/entities/laas-eligible-orders/laas-eligible-orders-update.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    /*{
      path: '/admin/email',
      name: 'Email',
      component: EmailComponent,
      meta: { authorities: [Authority.ADMIN] },
    },*/
    {
      path: '/admin/email/new',
      name: 'EmailCreate',
      component: EmailNew,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'marketplace',
      name: 'Marketplace',
      component: Marketplace,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'marketplace/new',
      name: 'MarketplaceCreate',
      component: MarketplaceUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'marketplace/:marketplaceId/edit',
      name: 'MarketplaceEdit',
      component: MarketplaceUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },

    {
      path: 'marketplace/:marketplaceId/view',
      name: 'MarketplaceView',
      component: MarketplaceDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'pos-implementation',
      name: 'PosImplementation',
      component: PosImplementation,
      meta: { authorities: [Authority.ADMIN, Authority.MARKETPLACE] },
    },
    {
      path: 'pos-implementation/new',
      name: 'PosImplementationCreate',
      component: PosImplementationUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'pos-implementation/:posImplementationId/edit',
      name: 'PosImplementationEdit',
      component: PosImplementationUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'pos-implementation/:posImplementationId/view',
      name: 'PosImplementationView',
      component: PosImplementationDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MARKETPLACE] },
    },
    {
      path: 'metadata',
      name: 'Metadata',
      component: Metadata,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'metadata/new',
      name: 'MetadataCreate',
      component: MetadataUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'metadata/:metadataId/edit',
      name: 'MetadataEdit',
      component: MetadataUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'metadata/:metadataId/view',
      name: 'MetadataView',
      component: MetadataDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'partner',
      name: 'Partner',
      component: Partner,
      meta: { authorities: [Authority.ADMIN, Authority.MARKETPLACE, Authority.POS_SYSTEM, Authority.POS_LAAS] },
    },
    {
      path: 'partner/new',
      name: 'PartnerCreate',
      component: PartnerUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM, Authority.POS_LAAS] },
    },
    {
      path: 'partner/:partnerId/edit',
      name: 'PartnerEdit',
      component: PartnerUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM, Authority.POS_LAAS] },
    },
    {
      path: 'partner/:partnerId/view',
      name: 'PartnerView',
      component: PartnerDetails,
      // meta: { authorities: [Authority.ADMIN, Authority.MARKETPLACE, Authority.POS_SYSTEM] },
      meta: { authorities: [Authority.ADMIN, Authority.MARKETPLACE] },
    },
    {
      path: '/partner/:partnerId/detail',
      name: 'PartnerDetail',
      component: PartnerMasterDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MARKETPLACE, Authority.POS_SYSTEM, Authority.POS_LAAS] },
    },
    {
      path: 'config-endpoint',
      name: 'ConfigEndpoint',
      component: ConfigEndpoint,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
      // meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'config-endpoint/new',
      name: 'ConfigEndpointCreate',
      component: ConfigEndpointUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
      // meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'config-endpoint/:configEndpointId/edit',
      name: 'ConfigEndpointEdit',
      component: ConfigEndpointUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
      // meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'config-endpoint/:configEndpointId/view',
      name: 'ConfigEndpointView',
      component: ConfigEndpointDetails,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
      // meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'config-operation',
      name: 'ConfigOperation',
      component: ConfigOperation,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'config-operation/new',
      name: 'ConfigOperationCreate',
      component: ConfigOperationUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'config-operation/:configOperationId/edit',
      name: 'ConfigOperationEdit',
      component: ConfigOperationUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'config-operation/:configOperationId/view',
      name: 'ConfigOperationView',
      component: ConfigOperationDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'config-pos-api-key',
      name: 'ConfigPosApiKey',
      component: ConfigPosApiKey,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    {
      path: 'config-pos-api-key/new',
      name: 'ConfigPosApiKeyCreate',
      component: ConfigPosApiKeyUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    {
      path: 'config-pos-api-key/:configPosApiKeyId/edit',
      name: 'ConfigPosApiKeyEdit',
      component: ConfigPosApiKeyUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    {
      path: 'config-pos-api-key/:configPosApiKeyId/view',
      name: 'ConfigPosApiKeyView',
      component: ConfigPosApiKeyDetails,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    {
      path: 'config-default-store-key',
      name: 'ConfigDefaultStoreKey',
      component: ConfigDefaultStoreKey,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'config-default-store-key/new',
      name: 'ConfigDefaultStoreKeyCreate',
      component: ConfigDefaultStoreKeyUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'config-default-store-key/:configDefaultStoreKeyId/edit',
      name: 'ConfigDefaultStoreKeyEdit',
      component: ConfigDefaultStoreKeyUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'config-default-store-key/:configDefaultStoreKeyId/view',
      name: 'ConfigDefaultStoreKeyView',
      component: ConfigDefaultStoreKeyDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'menu-update-request',
      name: 'MenuUpdateRequest',
      component: MenuUpdateRequest,
      meta: { authorities: [Authority.ADMIN, Authority.MARKETPLACE, Authority.POS_SYSTEM] },
    },
    {
      path: 'menu-update-request/new',
      name: 'MenuUpdateRequestCreate',
      component: MenuUpdateRequestUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    {
      path: 'menu-update-request/:menuUpdateRequestId/edit',
      name: 'MenuUpdateRequestEdit',
      component: MenuUpdateRequestUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    {
      path: 'menu-update-request/:menuUpdateRequestId/view',
      name: 'MenuUpdateRequestView',
      component: MenuUpdateRequestDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MARKETPLACE, Authority.POS_SYSTEM] },
    },
    {
      path: 'store',
      name: 'Store',
      component: Store,
      meta: { authorities: [Authority.ADMIN, Authority.MARKETPLACE, Authority.POS_SYSTEM] },
    },
    {
      path: 'store/new',
      name: 'StoreCreate',
      component: StoreUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    {
      path: '/store/new/:partnerId',
      name: 'PartnerStoreCreate',
      component: StoreUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    {
      path: 'store/:storeId/edit',
      name: 'StoreEdit',
      component: StoreUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    {
      path: '/store/:storeId/edit/:partnerId',
      name: 'PartnerStoreEdit',
      component: StoreUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    {
      path: 'store/:storeId/view',
      name: 'StoreView',
      component: StoreDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MARKETPLACE, Authority.POS_SYSTEM] },
    },
    {
      path: '/store/:storeId/view/:partnerId',
      name: 'PartnerStoreView',
      component: StoreDetails,
      meta: { authorities: [Authority.ADMIN, Authority.MARKETPLACE, Authority.POS_SYSTEM] },
    },
    {
      path: 'store-cron',
      name: 'StoreCron',
      component: StoreCron,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'store-cron/new',
      name: 'StoreCronCreate',
      component: StoreCronUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'store-cron/:storeCronId/edit',
      name: 'StoreCronEdit',
      component: StoreCronUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'store-cron/:storeCronId/view',
      name: 'StoreCronView',
      component: StoreCronDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'test-store',
      name: 'TestStore',
      component: TestStore,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'test-store/new',
      name: 'TestStoreCreate',
      component: TestStoreUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'test-store/:testStoreId/edit',
      name: 'TestStoreEdit',
      component: TestStoreUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'test-store/:testStoreId/view',
      name: 'TestStoreView',
      component: TestStoreDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'pos-test-store',
      name: 'PosTestStore',
      component: PosTestStore,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    {
      path: 'pos-test-store/new',
      name: 'PosTestStoreCreate',
      component: PosTestStoreUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'pos-test-store/:posTestStoreId/edit',
      name: 'PosTestStoreEdit',
      component: PosTestStoreUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'pos-test-store/:posTestStoreId/view',
      name: 'PosTestStoreView',
      component: PosTestStoreDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'country',
      name: 'Country',
      component: Country,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'country/new',
      name: 'CountryCreate',
      component: CountryUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'country/:countryId/edit',
      name: 'CountryEdit',
      component: CountryUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'country/:countryId/view',
      name: 'CountryView',
      component: CountryDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'marketplace-contact',
      name: 'MarketplaceContact',
      component: MarketplaceContact,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'marketplace-contact/new',
      name: 'MarketplaceContactCreate',
      component: MarketplaceContactUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'marketplace-contact/:marketplaceContactId/edit',
      name: 'MarketplaceContactEdit',
      component: MarketplaceContactUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'marketplace-contact/:marketplaceContactId/view',
      name: 'MarketplaceContactView',
      component: MarketplaceContactDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'pos-contact',
      name: 'PosContact',
      component: PosContact,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM, Authority.POS_LAAS, Authority.MARKETPLACE] },
    },
    {
      path: 'pos-contact/new',
      name: 'PosContactCreate',
      component: PosContactUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM, Authority.POS_LAAS] },
    },
    {
      path: 'pos-contact/:posContactId/edit',
      name: 'PosContactEdit',
      component: PosContactUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM, Authority.POS_LAAS] },
    },
    {
      path: 'pos-contact/:posContactId/view',
      name: 'PosContactView',
      component: PosContactDetails,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM, Authority.POS_LAAS] },
    },
    {
      path: 'integrator-api-key',
      name: 'IntegratorApiKey',
      component: IntegratorApiKey,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'integrator-api-key/new',
      name: 'IntegratorApiKeyCreate',
      component: IntegratorApiKeyUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'integrator-api-key/:integratorApiKeyId/edit',
      name: 'IntegratorApiKeyEdit',
      component: IntegratorApiKeyUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'integrator-api-key/:integratorApiKeyId/view',
      name: 'IntegratorApiKeyView',
      component: IntegratorApiKeyDetails,
      meta: { authorities: [Authority.ADMIN] },
    },

    {
      path: 'laas-orders',
      name: 'LaasOrdersList',
      component: LaasOrders,
      meta: { authorities: [Authority.POS_SYSTEM,Authority.ADMIN] },
    },

    {
      path: 'laas-orders/:orderId/create',
      name: 'LaasOrdersCreate',
      component: LaasOrdersCreate,
      meta: { authorities: [Authority.ADMIN, Authority.POS_SYSTEM] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
